import React from 'react';

function NavItem({ onClick, icon, label, isActive, colorKit, disabled }) {
  return (
    <button
      onClick={onClick}
      style={isActive ? { color: colorKit.accent } : {}}
      className={`flex flex-col items-center ${isActive ? '' : disabled ? 'text-gray-800' : 'text-gray-600 '}`}
    >
      {icon}
      <span className="text-xs mt-1">{label}</span>
    </button>
  );
}

export default NavItem;

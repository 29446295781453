import colorKits from "../colors";
import React, { useEffect, useState } from 'react';
import { createStarsInvoiceLink } from "../api/client";
import Locales from '../locale';

const products = [{
    "id": 1,
    "category": ["Boosters", "Бустеры"],
    "name": ["x2 Booster (1 hour)", "x2 Буст (1 час)"],
    "priceTON": 0.29,
    "priceXTR": 29,
    "description": ["Multiplies your silver income by two.", "Дважды умножает ваш приток серебра."]
  },
  {
    "id": 2,
    "category": ["Boosters", "Бустеры"],
    "name": ["x2 Booster (24 hour)", "x2 Буст (24 часа)"],
    "priceTON": 0.99,
    "priceXTR": 99,
    "description": ["Multiplies your silver income by two.", "Дважды умножает ваш приток серебра."]
  },
  {
    "id": 3,
    "category": ["Appearance", "Кастомизация"],
    "name": ["Blue Theme Upgrade", "Синий"],
    "priceTON": 0.02,
    "priceXTR": 8,
    "description": ["Turns your menu and nickname back into blue.", "Меняет палитру меню и цвет юзернейма обратно на синий."],
    "color": "blue"
  },
  {
    "id": 7,
    "category": ["Appearance", "Кастомизация"],
    "name": ["Green Theme Upgrade", "Зелёный"],
    "priceTON": 0.02,
    "priceXTR": 8,
    "description": ["Turns your menu and nickname into green.", "Меняет палитру меню и цвет юзернейма на зелёный."],
    "color": "green"
  },
  {
    "id": 4,
    "category": ["Appearance", "Кастомизация"],
    "name": ["Yellow Theme Upgrade", "Жёлтый"],
    "priceTON": 0.02,
    "priceXTR": 8,
    "description": ["Turns your menu and nickname into yellow.", "Меняет палитру меню и цвет юзернейма на жёлтый."],
    "color": "yellow"
  },
  {
    "id": 5,
    "category": ["Appearance", "Кастомизация"],
    "name": ["Orange Theme Upgrade", "Оранжевый"],
    "priceTON": 0.02,
    "priceXTR": 8,
    "description": ["Turns your menu and nickname into orange.", "Меняет палитру меню и цвет юзернейма на оранжевый."],
    "color": "orange"
  },
  {
    "id": 6,
    "category": ["Appearance", "Кастомизация"],
    "name": ["Red Theme Upgrade", "Красный"],
    "priceTON": 0.02,
    "priceXTR": 8,
    "description": ["Turns your menu and nickname into red.", "Меняет палитру меню и цвет юзернейма на красный."],
    "color": "red"
}];

function ProductCard({ product, colorKit, telegramInitData, telegramUserId, setRefreshRequired, giftTargetUser, paymentMode, performTransaction }) {
  return (
    <div
      className="border p-4 rounded shadow-md relative"
      style={{ borderColor: 'rgba(128, 128, 128, 0.3)', borderWidth: '1px' }}
    >
      {product.imageUrl && (
        <img
          src={product.imageUrl}
          alt={Locales.localizeSlice(product.name)}
          className="w-full h-48 object-cover mb-2 rounded"
          style={{ marginBottom: '15px' }}
        />
      )}
      <h2 className="text-xl jost-bold">{Locales.localizeSlice(product.name)}</h2>
      <p className="text-gray-600 mb-2 jost-regular">{Locales.localizeSlice(product.description)}</p>
      <button
        style={{ backgroundColor: product.color ? colorKits[product.color].accent : colorKit.accent }}
        className="text-white px-4 py-1.5 rounded jost-regular mt-1.5"
        onClick={() => {
          if (paymentMode == "ton") {
            performTransaction(
              product.priceTON,
              btoa([Number(giftTargetUser ? giftTargetUser.id : telegramUserId) - 26634, product.id].join(':'))
            );
          } else if (paymentMode == "stars") {
            (async () => {
              try {
                const { link } = await createStarsInvoiceLink(
                  telegramInitData,
                  product.id,
                  giftTargetUser ? giftTargetUser.id : telegramUserId
                );
  
                if (link) {
                  let executionCount = 0;
                  window.Telegram.WebApp.openTelegramLink(link);
                  const intervalId = setInterval(() => {
                    setRefreshRequired(true);
                    executionCount += 1;
                    if (executionCount >= 60) {
                      clearInterval(intervalId);
                    }
                  }, 1000);
                }
              } catch (e) {
                // ...
              }
            })();
          }
        }}
      >
        {Locales.localize("shop_buy_now")}
      </button>
      <div className="absolute bottom-4 right-4 flex items-center">
        <p className="font-bold text-lg mr-2">
          {paymentMode === "ton" ? product.priceTON : product.priceXTR}
        </p>
        {paymentMode === "ton" && <img src="ton.svg" alt="TON" className="w-5 h-5" />}
        {paymentMode === "stars" && <img src="stars.svg" alt="Telegram Stars" className="w-5 h-5" />}
      </div>
    </div>
  );  
}

function Shop({ colorKit, telegramInitData, telegramUserId, setRefreshRequired, giftTargetUser, paymentMode, setPaymentMode, performTransaction }) {
  const [animate, setAnimate] = useState(false);
  const categories = [...new Set(products.map((product) => Locales.localizeSlice(product.category)))];

  const togglePaymentMode = () => {
    const newPaymentMode = paymentMode === "ton" ? "stars" : "ton";
    setPaymentMode(newPaymentMode);
    setAnimate(true);

    localStorage.setItem('paymentMode', newPaymentMode);
    window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
  };

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  return (
    <div className="page-container h-screen overflow-hidden">
      <div className="flex items-center justify-between mt-1 mb-4">
        <h1 className="text-3xl jost-bold" style={{ color: colorKit.accent }}>{Locales.localize("shop_title")}</h1>
        <button
          onClick={togglePaymentMode}
          className={`border border-gray-400 rounded-full p-2 mr-2 flex items-center transition-transform duration-300 ${
            animate ? 'scale-110' : ''
          }`}
          style={{ borderColor: 'rgba(128, 128, 128, 0.5)', backgroundColor: 'transparent', fontSize: '0.9em' }}
        >
          {paymentMode === "ton" ? (
            <>
              <span className="mx-2">{Locales.localize("shop_switch_to_stars")}</span>
              <img src="stars.svg" alt={Locales.localize("shop_switch_to_stars")} className="w-5 h-5" />
            </>
          ) : (
            <>
              <span className="mx-2">{Locales.localize("shop_switch_to_ton")}</span>
              <img src="ton.svg" alt={Locales.localize("shop_switch_to_ton")} className="w-5 h-5" />
            </>
          )}
        </button>
      </div>
      <p className="jost-regular">{giftTargetUser ? "You will send chosen gift to " + giftTargetUser.username : Locales.localize("shop_welcome")}</p>

      <div className="products-container overflow-auto h-[calc(100vh-6rem)] mt-4 p-4 rounded-md" style={{ paddingBottom: '20rem' }}>
        {categories.map((category) => (
          <div key={category} className="mb-8">
            <h2 className="text-2xl font-bold mb-4">{category}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {products
                .filter((product) => product.category[Locales.selectedLocale] === category)
                .map((product) => (
                  <ProductCard key={product.id} product={product} colorKit={colorKit} telegramUserId={telegramUserId} performTransaction={performTransaction}
                    setRefreshRequired={setRefreshRequired} giftTargetUser={giftTargetUser} paymentMode={paymentMode} telegramInitData={telegramInitData} />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Shop;

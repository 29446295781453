import React, { useEffect, useState } from 'react';
import { getLeaderboard } from '../api/client';
import colorKits from "../colors";
import Locales from '../locale';
import Countdown from './Countdown';
import { Emoji } from 'react-apple-emojis';

function getCurrentUsername() {
  return window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe.user 
    && window.Telegram.WebApp.initDataUnsafe.user.username;
}

function Leaderboard({ colorKit, telegramInitData, setDisguisedUser, setCurrentPage, setRefreshRequired, telegramUserId, paymentMode }) {
  const [leaders, setLeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("silver");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const leaderboard = await getLeaderboard(telegramInitData, section);
      setLeaders(leaderboard);
      setLoading(false);
    })();

    const intervalId = setInterval(async () => {
      if (section) {
        try {
          const leaderboard = await getLeaderboard(telegramInitData, section);
          setLeaders(leaderboard);
        } catch (e) {
          // ...
        }
      }
    }, 11000);

    return () => {
      clearInterval(intervalId);
    };
  }, [telegramInitData, section]);

  return (
    <div className="page-container">
      <h1 className="text-3xl jost-bold mb-4" style={{ color: colorKit.accent }}>{Locales.localize("leaderboard_title")}</h1>
      <div 
        className="leaderboard-section overflow-x-auto overflow-y-auto h-[calc(100vh-6rem)]" 
        style={{ paddingBottom: '14rem' }}
      >
        <div class="flex justify-between mt-2 mb-6">
          {false && <button
            id="tab0"
            onClick={() => setSection("drop")}
            class={`w-full py-2 text-center ${section === "drop" ? "text-white" : "text-gray-400 hover:text-white"} jost-bold border-b-4 ${
              section === "drop" ? "" : "border-transparent"} transition duration-300`}
            style={{ borderColor: section === "drop" ? colorKit.secondary : null }}
          >
            ⚡ 2h Drop
          </button>}
          <button
            id="tab1"
            onClick={() => setSection("silver")}
            class={`w-full py-2 text-center ${section === "silver" ? "text-white" : "text-gray-400 hover:text-white"} jost-bold border-b-4 ${
              section === "silver" ? "" : "border-transparent"} transition duration-300`}
            style={{ borderColor: section === "silver" ? colorKit.secondary : null }}
          >
            {Locales.localize("leaderboard_silver_label")}
          </button>
          <button
            id="tab2"
            onClick={() => setSection("performance")}
            class={`w-full py-2 text-center ${section === "performance" ? "text-white" : "text-gray-400 hover:text-white"} jost-bold border-b-4 ${
              section === "performance" ? "" : "border-transparent"} transition duration-300`}
            style={{ borderColor: section === "performance" ? colorKit.secondary : null }}
          >
            {Locales.localize("leaderboard_performance_label")}
          </button>
          <button
            id="tab3"
            onClick={() => setSection("birds")}
            class={`w-full py-2 text-center ${section === "birds" ? "text-white" : "text-gray-400 hover:text-white"} jost-bold border-b-4 ${
              section === "birds" ? "" : "border-transparent"} transition duration-300`}
            style={{ borderColor: section === "birds" ? colorKit.secondary : null }}
          >
            {Locales.localize("leaderboard_birds_label")}
          </button>
        </div>
        {loading ? (
          <div className="text-center py-4">{Locales.localize("loading")}</div>
        ) : (<>
          {section == "drop" && <div className="flex items-center justify-center" style={{ color: colorKit.secondary }}>
            Next Reward In
          </div>}
          {section == "drop" && <div className="flex items-center justify-center">
            <Countdown targetDate="2024-10-12T23:00:00" />
          </div>}
          {section == "drop" && (
            <button
              style={{ backgroundColor: colorKit.accent }}
              className="invite-button w-full text-white font-semibold py-2 px-4 mt-4 mb-4 rounded jost-regular"
            >
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Become a King for{" "}
                {0.3 * (paymentMode === "stars" ? 100 : paymentMode === "ton" ? 1 : 1)}
                {paymentMode === "ton" && (
                  <img
                    src="ton.svg"
                    alt="TON"
                    style={{ marginLeft: '6px', width: '20px', height: '20px' }}
                  />
                )}
                {paymentMode === "stars" && (
                  <img
                    src="stars.svg"
                    alt="Telegram Stars"
                    style={{ marginLeft: '6px', width: '20px', height: '20px' }}
                  />
                )}
              </span>
            </button>
          )}
          <table className="leaderboard-table min-w-full text-left">
            <thead>
              <tr style={{ backgroundColor: colorKit.primary }}>
                <th className="py-2 px-4 text-white jost-bold" style={{ color: colorKit.accent }}>{Locales.localize("leaderboard_rank_label")}</th>
                <th className="py-2 px-4 text-white jost-bold"></th>
                <th className="py-2 px-4 text-white jost-bold" style={{ color: colorKit.secondary }}>{section == "silver" ? Locales.localize("leaderboard_silver_label") 
                  : section == "performance" ? Locales.localize("leaderboard_performance_label") : section == "birds" ? Locales.localize("leaderboard_birds_label") 
                  : section == "drop" ? "Reward" : null}</th>
              </tr>
            </thead>
            <tbody>
              {(leaders || []).map((leader, index) => (
                <tr key={leader.id}>
                  <td className="py-2 px-4 jost-regular" style={{ color: colorKit.accent }}>#{index + 1}</td>
                  <td
                    className="py-2 px-4 jost-regular flex items-center"
                    style={{ color: leader.Member.color ? colorKits[leader.Member.color].accent : leader.Member.username 
                        == '@' + getCurrentUsername() ? "#e6df19" : null, maxWidth: "175px", overflow: "hidden",
                        whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                    onClick={() => {
                      if (leader.Member && leader.Member.id - telegramUserId != 0) {
                        setDisguisedUser(leader.Member);
                      }

                      setCurrentPage("home");
                      setRefreshRequired(true);
                    }}
                  >
                    {(leader.Member.photoUrl && leader.Member.photoUrl.length > 0) && (
                      <img 
                        src={leader.Member.photoUrl} 
                        alt={`${leader.Member.username}'s profile`} 
                        className="w-8 h-8 rounded-full mr-2" 
                      />
                    )}
                    <span>{leader.Member.username}</span>
                  </td>
                  <td className={`py-2 px-4 jost-regular whitespace-nowrap ${section == "drop" && "shining-text"}`} 
                    style={{ color: "#e3e3e3" }}>{section != "drop" ? Number(leader.Score).toLocaleString('en').replace(/,/g, ' ') : "+ 1000 Gold"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>)}
      </div>
    </div>
  );
}

export default Leaderboard;

import * as locales from './locale.json';

const userLocale = navigator.language || navigator.userLanguage;
const selectedLocale = userLocale.startsWith("ru") ? 1 : userLocale
    .startsWith("en") ? 0 : userLocale.startsWith("zh") ? 2 : userLocale.startsWith("uk") 
    ? 3 : 0;

const Locales = {
    selectedLocale,
    pluralizeRussian: (number, [singular, few, many]) => {
        const n = Math.abs(number);
        const lastTwoDigits = n % 100;
        const lastDigit = n % 10;
      
        if (lastTwoDigits > 10 && lastTwoDigits < 20) {
          return many;
        }
        if (lastDigit === 1) {
          return singular;
        }
        if (lastDigit >= 2 && lastDigit <= 4) {
          return few;
        }
      
        return many;
    },
    localize: (path, extra = -1) => {
        if (locales[path] && typeof locales[path][selectedLocale] == "string") {
            return locales[path][selectedLocale];
        }

        if (locales[path] && selectedLocale == 1 && extra != -1 && 
            typeof locales[path][selectedLocale] == "object") {
            return Locales.pluralizeRussian(extra, locales[path][selectedLocale]);
        }
        
        if (locales[path] && locales[path][0]) {
            return locales[path][0];
        }

        return '?';
    },
    localizeSlice: source => {
        if (source && typeof source[selectedLocale] == "string") {
            return source[selectedLocale];
        }
        
        if (source && source[0]) {
            return source[0];
        }

        return '?';
    },
    all: locales
};

export default Locales;

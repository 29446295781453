import { CoinVertical, Info } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { Emoji } from 'react-apple-emojis';
import Locales from '../locale';

const Xes = [
  249.52,
  69.93,
  27.97,
  11.18,
  6.71,
  4.02,
  2.41,
  1.93,
  1.54,
  1.23
];

function Field({ colorKit }) {
  const [animate, setAnimate] = useState(false);
  const [paymentMode, setPaymentMode] = useState('silver');
  const [fieldSetup, setFieldSetup] = useState({
    isGameStarted: false,
    isGameOver: false,
    activeRow: 9,
    grid: [
      [ 0, 1, 0, 0, 0 ],
      [ 1, 0, 1, 0, 0 ],
      [ 1, 0, 1, 0, 0 ],
      [ 1, 0, 0, 1, 1 ],
      [ 0, 1, 1, 1, 0 ],
      [ 0, 1, 1, 0, 1 ],
      [ 1, 1, 1, 1, 0 ],
      [ 1, 0, 1, 1, 1 ],
      [ 1, 0, 1, 1, 1 ],
      [ 1, 1, 0, 1, 1 ]
    ],
  });

  useEffect(() => {
    // onGameStart();
  }, []);

  const togglePaymentMode = () => {
    const newPaymentMode = paymentMode === "silver" ? "ton" : "silver";
    setPaymentMode(newPaymentMode);
    setAnimate(true);

    localStorage.setItem('paymentMode', newPaymentMode);
    window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
  };

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  const onFieldClick = (rowIndex, colIndex) => {
    if (rowIndex != fieldSetup.activeRow) {
      return;
    }

    if (fieldSetup.isGameOver) {
      return;
    }

    const grid = Object.assign([], fieldSetup.grid);
    grid[rowIndex][colIndex] += 2;

    const isGameOver = grid[rowIndex][colIndex] == 2;

    setFieldSetup({
      ...fieldSetup,
      activeRow: fieldSetup.activeRow - (isGameOver ? 0 : 1),
      isGameOver: isGameOver,
      grid
    });

    if (isGameOver) {
      setTimeout(onGameOver, 800);
      return;
    }

    let container = document.querySelector("#scrollMe");
    let vh = container.scrollHeight / 10;
    let pos = (vh * 0.5) * fieldSetup.activeRow;
    if (pos < vh) {
      pos = 0;
    }
    
    container.scrollTo({
      behavior: 'smooth',
      top: pos,
      left: 0
    });
  };

  const onGameStart = () => {
    setFieldSetup({
      ...fieldSetup,
      isGameStarted: true
    });

    const container = document.querySelector("#scrollMe");
    container.scrollTo(0, 0);
    container.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onGameOver = () => {
    alert("Game Over");
    setTimeout(() => {
      setFieldSetup({
        isGameStarted: false,
        isGameOver: false,
        activeRow: 9,
        grid: [
          [ 0, 1, 0, 0, 0 ],
          [ 1, 0, 1, 0, 0 ],
          [ 1, 0, 1, 0, 0 ],
          [ 1, 0, 0, 1, 1 ],
          [ 0, 1, 1, 1, 0 ],
          [ 0, 1, 1, 0, 1 ],
          [ 1, 1, 1, 1, 0 ],
          [ 1, 0, 1, 1, 1 ],
          [ 1, 0, 1, 1, 1 ],
          [ 1, 1, 0, 1, 1 ]
        ],
      });
    }, 1000);
  };

  return (
    <div className="page-container">
      <div className="flex items-center justify-between mt-1 mb-4">
        {!fieldSetup.isGameStarted && <div
          className="fixed right-5 inline-flex items-center justify-center rounded-full w-8 h-8 mb-1.5 cursor-pointer shadow-md"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Info size={24} color="#ffffff" style={{ opacity: 0.5 }} />
        </div>}
        <h1 className="text-3xl mb-4 mt-1 jost-bold">Field</h1>
        {!fieldSetup.isGameStarted && <button
          onClick={togglePaymentMode}
          className={`border border-gray-400 rounded-full p-2 mb-1.5 mr-16 flex items-center transition-transform duration-300 ${
            animate ? 'scale-110' : ''
          }`}
          style={{ borderColor: 'rgba(128, 128, 128, 0.5)', backgroundColor: 'transparent', fontSize: '0.9em' }}
        >
          {paymentMode === "ton" ? (
            <>
              <span className="mx-2">{"Switch to Silver"}</span>
              <CoinVertical
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  display: "inline-block"
                }}
              />
            </>
          ) : (
            <>
              <span className="mx-2">{Locales.localize("shop_switch_to_ton")}</span>
              <img src="ton.svg" alt={Locales.localize("shop_switch_to_ton")} className="w-5 h-5" />
            </>
          )}
        </button>}
      </div>
      {!fieldSetup.isGameStarted && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="mb-60"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: "300px",
              width: "100%",
            }}
          >
            <div className="w-full">
              <label className="block text-lg mb-2 jost-bold">Enter Bet Amount</label>
              <input
                type="number"
                value={"0.1"}
                min={0.1}
                step={0.1}
                className="w-full border bg-black border-gray-300 rounded px-4 py-2"
              />
            </div>
            <div className="w-full mt-1 flex items-center">
              <input
                type="text"
                value={"2fd4e1c67a2d28fced849ee1bb76e7391b93eb12"}
                className="flex-1 border bg-black rounded-l px-4 py-2"
              />
              <button style={{ backgroundColor: "rgb(56 56 56 / 75%)" }} className="border text-black px-4 py-2 rounded-r"
                onClick={() => {
                  alert("This is a UI demo. No functionality has been implemented yet.");
                }}>
                <Emoji
                  name="game-die"
                  style={{
                    width: "1.25em",
                    height: "1.25em",
                    display: "inline-block"
                  }}
                />
              </button>
            </div>
            <button
              style={{ backgroundColor: colorKit.accent }}
              className="mt-2 w-full text-white py-2 px-4 rounded-lg focus:outline-none jost-regular"
              onClick={onGameStart}
            >
              Start
            </button>
            <p className="text-sm text-center mt-8">You can <span style={{ color: colorKit.accent }}
              >learn more</span> about the algorithm that will be used to process your bet.</p>
          </div>
        </div>
      )}
      <div className="overflow-auto h-[calc(100vh-20rem)] mt-4 rounded-md flex justify-center" id="scrollMe">
        <div className="flex flex-col">
            {Array.from({ length: 10 }).map((_, rowIndex) => {
              const isActive = rowIndex === fieldSetup.activeRow;
              return (
                <div
                  key={rowIndex}
                  className="flex items-center"
                  style={{
                    marginTop: "5px",
                    backgroundColor: isActive ? "rgb(255 255 255 / 10%)" : "transparent",
                    padding: "0",
                    borderRadius: isActive ? "5px" : "0",
                  }}
                >
                  <div
                    className="flex justify-center items-center text-xs mr-2"
                    style={{
                      backgroundColor: fieldSetup.activeRow < rowIndex ? "rgb(255 255 255 / 25%)" : "rgb(255 255 255 / 10%)",
                      width: "14vw",
                      height: "14vw",
                      maxWidth: "56px",
                      maxHeight: "56px",
                      textAlign: "center",
                      lineHeight: "56px",
                      borderRadius: "5px",
                    }}
                  >
                    {`x${Xes[rowIndex]}`}
                  </div>

                  <div className="grid grid-cols-5 gap-2 sm:gap-2">
                    {Array.from({ length: 5 }).map((_, colIndex) => (
                      <div
                        key={colIndex}
                        className="rounded-md flex justify-center items-center"
                        style={{
                          backgroundColor: "rgb(255 255 255 / 15%)",
                          width: "14vw",
                          height: "14vw",
                          maxWidth: "56px",
                          maxHeight: "56px",
                        }}
                        onClick={() => {
                          onFieldClick(rowIndex, colIndex);
                        }}
                      >
                        {fieldSetup.activeRow - (fieldSetup.isGameOver ? 10 : 0) < rowIndex ? ([1, 3].includes(fieldSetup.grid[rowIndex][colIndex]) ? <Emoji name="chicken" 
                          style={{ width: "11vw", height: "11vw", maxWidth: "48px", maxHeight: "48px", opacity: fieldSetup.grid[rowIndex][colIndex] == 3 ? "1.0" : "0.3" }} /> : (
                            fieldSetup.activeRow - (fieldSetup.isGameOver ? 10 : 0) < rowIndex ? <Emoji name="skull" style={{ width: "11vw", height: 
                            "11vw", maxWidth: "48px", maxHeight: "48px", opacity: fieldSetup.grid[rowIndex][colIndex] == 2 ? "1.0" : "0.3" }} /> : ""
                          )) : ""}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Field;

import React, { useEffect, useState } from 'react';
import BottomNavbar from './components/BottomNavbar';
import Home from './components/Home';
import Tasks from './components/Tasks';
import Shop from './components/Shop';
import About from './components/About';
import Vignette from './components/Vignette';
import Leaderboard from './components/Leaderboard';
import Payout from './components/Payout';
import AnimatedPage from './components/AnimatedPage';

import { 
  TonConnectUIProvider,
  useTonConnectUI,
  useTonWallet
} from '@tonconnect/ui-react';
import { beginCell, toNano } from '@ton/core';
import Field from './components/Field';
import Frens from './components/Frens';
import Games from './components/Games';

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [paymentMode, setPaymentMode] = useState('stars');
  const [telegramInitData, setTelegramInitData] = useState(null);
  const [telegramUserId, setTelegramUserId] = useState(null);
  const [giftTargetUser, setGiftTargetUser] = useState(null);
  const [disguisedUser, setDisguisedUser] = useState(0);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [tasksRefreshRequired, setTasksRefreshRequired] = useState(false);
  const [isVignetteShimming, setVignetteShimming] = useState(0);
  const [colorKit, setColorKit] = useState({});
  const [resources, setResources] = useState({
    x2_booster_ends: null,
    birds: [0, 0, 0, 0, 0],
    silver: 0,
    gold: 0,
  });

  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const performTransaction = async (amount = "0.02", message) => {
    if (wallet == null) {
      tonConnectUI.openModal();
    } else {
      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(message)
        .endCell();

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: [
          {
            address: "UQD_d3zGd8lBnrVO2rqC-eolbht0knTbHAnjFktwn8lsHqd_",
            amount: toNano(amount).toString(),
            payload: body.toBoc().toString("base64")
          }
        ]
      };

      try {
        let executionCount = 0;
        await tonConnectUI.sendTransaction(transaction);
        const intervalId = setInterval(() => {
          setRefreshRequired(true);
          executionCount += 1;
          if (executionCount >= 40) {
            clearInterval(intervalId);
          }
        }, 2000);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const savedPaymentMode = localStorage.getItem('paymentMode');
    if (savedPaymentMode) {
      setPaymentMode(savedPaymentMode);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        setRefreshRequired(true);
      }
    });
  }, []);

  useEffect(() => {
    setTasksRefreshRequired(true);
  }, [refreshRequired]);

  if (!telegramUserId) {
    if (window.Telegram && window.Telegram.WebApp) {
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length > 0) {
        setTelegramInitData(window.Telegram.WebApp.initData);

        if (window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
          setTelegramUserId(window.Telegram.WebApp.initDataUnsafe.user.id);
        }
      }
    }
  }

  const renderPage = page => {
    switch (page) {
      case 'tasks':
        return <Tasks telegramInitData={telegramInitData} colorKit={colorKit} resources={resources} setCurrentPage={setCurrentPage} telegramUserId={telegramUserId} paymentMode={paymentMode} 
          performTransaction={performTransaction} setRefreshRequired={setRefreshRequired} tasksRefreshRequired={tasksRefreshRequired} setTasksRefreshRequired={setTasksRefreshRequired} refreshRequired={refreshRequired} />;
      case 'shop':
        return <Shop colorKit={colorKit} telegramInitData={telegramInitData} telegramUserId={telegramUserId} setRefreshRequired={setRefreshRequired} giftTargetUser={giftTargetUser} paymentMode={paymentMode} setPaymentMode={setPaymentMode} performTransaction={performTransaction} />;
      case 'frens':
        return <Frens colorKit={colorKit} telegramUserId={telegramUserId} telegramInitData={telegramInitData} setGiftTargetUser={setGiftTargetUser} setCurrentPage={setCurrentPage} setDisguisedUser={setDisguisedUser} setRefreshRequired={setRefreshRequired} />;
      case 'games':
        return <Games colorKit={colorKit} />;
      case 'field':
        return <Field colorKit={colorKit} />;
      case 'leaderboard':
        return <Leaderboard colorKit={colorKit} telegramInitData={telegramInitData} setDisguisedUser={setDisguisedUser} setCurrentPage={setCurrentPage} setRefreshRequired={setRefreshRequired} telegramUserId={telegramUserId} paymentMode={paymentMode} />;
      case 'payout':
        return <Payout colorKit={colorKit} telegramInitData={telegramInitData} resources={resources} setCurrentPage={setCurrentPage} />;
      case 'about':
        return <About colorKit={colorKit} />;
      default:
        return <Home telegramInitData={telegramInitData} refreshRequired={refreshRequired} setRefreshRequired={setRefreshRequired} colorKit={colorKit} setColorKit={setColorKit} 
          resources={resources} setResources={setResources} setVignetteShimming={setVignetteShimming} setCurrentPage={setCurrentPage} disguisedUser={disguisedUser} setDisguisedUser={setDisguisedUser}
          setGiftTargetUser={setGiftTargetUser} />;
    }
  };

  return (
    <div className="min-h-screen flex flex-col overflow-hidden bg-black text-white">
      <Vignette isVignetteShimming={isVignetteShimming} colorKit={colorKit} />
      <main className="flex-wrapper p-4">
        <div className="page-container">
          <AnimatedPage currentPage={currentPage} renderPage={renderPage} />
        </div>
      </main>
      <BottomNavbar colorKit={colorKit} currentPage={currentPage} setCurrentPage={setCurrentPage} setRefreshRequired={setRefreshRequired} setGiftTargetUser={setGiftTargetUser} setDisguisedUser={setDisguisedUser} />
    </div>
  );
}

export default App;

import React from 'react';
import Locales from '../locale';

function About({ colorKit }) {
  return (
    <div className="page-container">
      <h1 className="text-3xl mb-4 mt-1 jost-bold">{Locales.localize("about_chicker_title")}</h1>
      {Locales.selectedLocale !== 1 && <div className="overflow-auto h-[calc(100vh-6rem)] mt-4 p-4 rounded-md" style={{ paddingBottom: '20rem' }}>
        <p className="mb-4 jost-regular">
            Welcome to our project! We are a <span style={{ color: colorKit.accent }}>passionate team</span> dedicated to creating
            <span style={{ color: colorKit.accent }}> immersive</span> and <span style={{ color: colorKit.accent }}>engaging</span> TWA that resonate with players across
            the globe.
        </p>
        <p className="mb-4 jost-regular">
            Our <span style={{ color: colorKit.accent }}>latest game</span> challenges you to tap on <span style={{ color: colorKit.accent }}>chickens</span> to purchase them, and they will start earning <span style={{ color: colorKit.accent }}>silver</span> for you, even when you're offline. Chickens keep generating silver on your account for up to <span style={{ color: colorKit.accent }}>48 hours</span> after your last login, ensuring you're always making progress. 
        </p>
        <p className="mb-4 jost-regular">
            Invite your <span style={{ color: colorKit.accent }}>friends</span> to join the fun, and we'll credit up to <span style={{ color: colorKit.accent }}>half</span> of their contributions to your account as a reward. This way, you can grow your collection of <span style={{ color: colorKit.accent }}>chickens</span> and generate even more <span style={{ color: colorKit.accent }}>metals</span> faster!
        </p>
        <p className="jost-regular">
            Stay tuned for our <span style={{ color: colorKit.accent }}>upcoming releases</span> and <span style={{ color: colorKit.accent }}>updates</span>, and feel free to 
            connect with us through our <span style={{ color: colorKit.accent }}>social media channels</span>.
        </p>
      </div>}
      {Locales.selectedLocale === 1 && <div className="overflow-auto h-[calc(100vh-6rem)] mt-4 p-4 rounded-md" style={{ paddingBottom: '20rem' }}>
        <p className="mb-4 jost-regular">
            Добро пожаловать в наш проект! Мы - <span style={{ color: colorKit.accent }}>страстная команда</span>, посвятившая себя созданию
            <span style={{ color: colorKit.accent }}> погружающих</span> и <span style={{ color: colorKit.accent }}>увлекательных</span> TWA, которые находят отклик у игроков по всему миру.
        </p>
        <p className="mb-4 jost-regular">
            Наша <span style={{ color: colorKit.accent }}>последняя игра</span> предлагает вам нажимать на <span style={{ color: colorKit.accent }}>курочек</span>, чтобы покупать их, и они начнут приносить вам <span style={{ color: colorKit.accent }}>серебро</span>, даже когда вы офлайн. Курочки продолжают генерировать серебро на вашем аккаунте в течение <span style={{ color: colorKit.accent }}>48 часов</span> после последнего входа, что гарантирует постоянный прогресс.
        </p>
        <p className="mb-4 jost-regular">
            Пригласите своих <span style={{ color: colorKit.accent }}>друзей</span> присоединиться, и мы начислим вам до <span style={{ color: colorKit.accent }}>половины</span> их вкладов в качестве награды. Таким образом, вы сможете быстрее увеличивать свою коллекцию <span style={{ color: colorKit.accent }}>курочек</span> и генерировать еще больше <span style={{ color: colorKit.accent }}>металлов</span>!
        </p>
        <p className="jost-regular">
            Следите за нашими <span style={{ color: colorKit.accent }}>будущими релизами</span> и <span style={{ color: colorKit.accent }}>обновлениями</span>, а также не стесняйтесь подключаться к нам через наши <span style={{ color: colorKit.accent }}>социальные сети</span>.
        </p>
      </div>}
    </div>
  );
}

export default About;

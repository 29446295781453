const COLOR_KITS = {
  "green": {
    gradient: "rgb(0 255 103 / 17.5%)",
    shadow: "rgb(125 255 98 / 30%)",
    accent: "rgb(22 163 74)",
    secondary: "rgb(46 207 118)"
  },
  "blue": {
    gradient: "rgb(0 135 255 / 25%)",
    shadow: "rgb(98 182 255 / 30%)",
    accent: "rgb(59 130 246)",
    secondary: "rgb(123 169 252)"
  },
  "yellow": {
    gradient: "rgb(255 221 0 / 30%)",
    shadow: "rgb(255 235 103 / 30%)",
    accent: "rgb(234 179 8)",
    secondary: "rgb(247 196 86)"
  },
  "red": {
    gradient: "rgb(255 0 56 / 35%)",
    shadow: "rgb(255 98 98 / 30%)",
    accent: "rgb(220 38 38)",
    secondary: "rgb(242 116 116)"
  },
  "orange": {
    gradient: "rgb(255 148 0 / 25%)",
    shadow: "rgb(255 182 98 / 30%)",
    accent: "rgb(249 115 22)",
    secondary: "rgb(253 171 98)"
  },
  "purple": {
    gradient: "rgb(148 0 255 / 25%)",
    shadow: "rgb(182 98 255 / 30%)",
    accent: "rgb(168 85 247)",
    secondary: "rgb(196 153 251)"
  },
  "pink": {
    gradient: "rgb(255 0 178 / 25%)",
    shadow: "rgb(255 98 182 / 30%)",
    accent: "rgb(236 72 153)",
    secondary: "rgb(251 165 221)"
  },
  "teal": {
    gradient: "rgb(0 255 210 / 25%)",
    shadow: "rgb(98 255 235 / 30%)",
    accent: "rgb(13 148 136)",
    secondary: "rgb(94 234 212)"
  },
  "cyan": {
    gradient: "rgb(0 255 255 / 25%)",
    shadow: "rgb(98 255 255 / 30%)",
    accent: "rgb(6 182 212)",
    secondary: "rgb(125 211 252)"
  },
  "brown": {
    gradient: "rgb(165 42 42 / 25%)",
    shadow: "rgb(198 88 88 / 30%)",
    accent: "rgb(120 72 48)",
    secondary: "rgb(150 110 80)"
  },
  "gray": {
    gradient: "rgb(128 128 128 / 25%)",
    shadow: "rgb(168 168 168 / 30%)",
    accent: "rgb(107 114 128)",
    secondary: "rgb(156 163 175)"
  },
  "black": {
    gradient: "rgb(0 0 0 / 25%)",
    shadow: "rgb(58 58 58 / 30%)",
    accent: "rgb(31 41 55)",
    secondary: "rgb(75 85 99)"
  },
  "lightGreen": {
    gradient: "rgb(173 255 47 / 25%)",
    shadow: "rgb(203 255 103 / 30%)",
    accent: "rgb(132 204 22)",
    secondary: "rgb(163 232 78)"
  },
  "lightBlue": {
    gradient: "rgb(173 216 230 / 25%)",
    shadow: "rgb(198 229 242 / 30%)",
    accent: "rgb(96 165 250)",
    secondary: "rgb(147 197 253)"
  },
  "gold": {
    gradient: "rgb(255 215 0 / 25%)",
    shadow: "rgb(255 232 103 / 30%)",
    accent: "rgb(212 175 55)",
    secondary: "rgb(230 189 82)"
  },
  "silver": {
    gradient: "rgb(192 192 192 / 25%)",
    shadow: "rgb(222 222 222 / 30%)",
    accent: "rgb(163 163 163)",
    secondary: "rgb(189 189 189)"
  },
  "bronze": {
    gradient: "rgb(205 127 50 / 25%)",
    shadow: "rgb(229 155 88 / 30%)",
    accent: "rgb(176 98 50)",
    secondary: "rgb(199 125 82)"
  },
  "violet": {
    gradient: "rgb(238 130 238 / 25%)",
    shadow: "rgb(255 182 255 / 30%)",
    accent: "rgb(168 85 247)",
    secondary: "rgb(196 153 251)"
  },
  "magenta": {
    gradient: "rgb(255 0 255 / 25%)",
    shadow: "rgb(255 98 255 / 30%)",
    accent: "rgb(219 39 119)",
    secondary: "rgb(240 109 180)"
  },
  "indigo": {
    gradient: "rgb(75 0 130 / 25%)",
    shadow: "rgb(98 98 255 / 30%)",
    accent: "rgb(99 102 241)",
    secondary: "rgb(165 180 252)"
  }
};

module.exports = COLOR_KITS;

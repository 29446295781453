import React, { useEffect, useState } from 'react';
import { Gift } from '@phosphor-icons/react';
import { getFriends } from '../api/client';
import colorKits from "../colors";
import Locales from '../locale';

function FrensInfo() {
  return (
    <div className="mt-8">
      <p className="jost-regular">
        {Locales.localize("frens_invite_promotional")}
      </p>
    </div>
  );
}

function Frens({ colorKit, telegramUserId, telegramInitData, setCurrentPage, setGiftTargetUser, setDisguisedUser, setRefreshRequired }) {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleInviteClick = () => {
    const text = Locales.localize("share_message");
    const uri = "t.me/yourchicksbot?start=" + (Number(telegramUserId) + 26634);
    window.Telegram.WebApp.openTelegramLink("https://t.me/share/url?url=" + encodeURIComponent(uri) + "&text=" + encodeURIComponent(text));
  };

  const handleGiftClick = friend => {
    setGiftTargetUser(friend);
    setCurrentPage("shop");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getFriends(telegramInitData);
      setFriends((response || {}).friends);
      setLoading(false);
    })();
  }, [telegramInitData]);

  return (
    <div className="page-container">
      <h1 className="text-3xl jost-bold mb-4 mt-1" style={{ color: colorKit.secondary }}>{Locales.localize("frens_title")}</h1>
      {loading ? (
        <div className="loading-section text-center mt-8">
          <p className="text-lg mb-4 jost-regular">{Locales.localize("loading")}</p>
        </div>
      ) : (
        <>
          {(friends || []).length > 0 ? (
            <div className="overflow-auto h-[calc(100vh-6rem)] mt-4 p-4 rounded-md" style={{ paddingBottom: '20rem' }}>
              <div className="friends-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {(friends || []).map((friend, index) => (
                  <div
                    key={index}
                    className="friend-card shadow-md p-4 rounded-lg border flex justify-between items-center"
                    style={{ borderColor: "rgba(85, 85, 85, 0.5)", boxShadow: "5px 5px 50px " + colorKit.shadow }}
                    onClick={() => {
                      setDisguisedUser(friend);
                      setCurrentPage("home");
                      setRefreshRequired(true);
                    }}
                  >
                    <p className="text-base jost-regular"
                      style={{
                        color: friend.color ? colorKits[friend.color]?.accent ?? null : null
                      }}
                    >
                      {friend.username}
                    </p>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGiftClick(friend);
                      }}
                      className="gift-button p-2 rounded"
                    >
                      <Gift size={24} style={{ color: colorKit.accent }} />
                    </button>
                  </div>
                ))}
              </div>
              <div className="invite-section text-center mt-4">
                <button
                  onClick={handleInviteClick}
                  style={{ backgroundColor: colorKit.accent, marginBottom: "24px" }}
                  className="invite-button w-full text-white font-semibold py-2 px-4 rounded jost-regular"
                >
                  {Locales.localize("frens_share_invite")}
                </button>
              </div>
              <FrensInfo />
            </div>
          ) : (
            <div className="no-friends-section text-center mt-8">
              <p className="text-lg mb-4 jost-regular">{Locales.localize("frens_no_friends_yet")}</p>
              <div className="invite-section text-center mt-4">
                <button
                  onClick={handleInviteClick}
                  style={{ backgroundColor: colorKit.accent }}
                  className="invite-button w-full text-white font-semibold py-2 px-4 rounded jost-regular"
                >
                  {Locales.localize("frens_share_invite")}
                </button>
              </div>
              <FrensInfo />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Frens;

const API_BASE_URL = 'https://back.xgodot.net';

async function getDashboard(initData, disguiseUserId = null) {
    try {
        const response = await fetch(`${API_BASE_URL}/dashboard` + (
            disguiseUserId ? "?disguise_user_id=" + disguiseUserId : ""), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
    }
}

async function getLeaderboard(initData, section) {
    try {
        const response = await fetch(`${API_BASE_URL}/leaderboard?section=` + section, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch leaderboard data:', error);
    }
}

async function getFriends(initData) {
    try {
        const response = await fetch(`${API_BASE_URL}/friends`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch leaderboard data:', error);
    }
}

async function getTasks(initData) {
    try {
        const response = await fetch(`${API_BASE_URL}/tasks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch leaderboard data:', error);
    }
}

async function claimTaskReward(initData, taskId) {
    try {
        const response = await fetch(`${API_BASE_URL}/claim_task_reward?task_id=` + taskId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch claim_task_reward data:', error);
    }
}

async function buyAChick(initData, birdIndex, buyAmount = 1) {
    try {
        const response = await fetch(`${API_BASE_URL}/buy_a_chick?bird=${birdIndex}&q=` + buyAmount, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to buy a chick:', error);
    }
}

async function createStarsInvoiceLink(initData, payload, targetUserId) {
    try {
        const response = await fetch(`${API_BASE_URL}/stars_invoice?payload=${encodeURIComponent(payload)}&target_user_id=${targetUserId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Init-Data': initData,
                'X-YC-Locale': navigator.language || navigator.userLanguage
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to create Stars Invoice chick:', error);
    }
}

module.exports = { getDashboard, buyAChick, getLeaderboard, getFriends, getTasks, claimTaskReward, createStarsInvoiceLink };

import React from 'react';

function Games({ colorKit }) {
  return (
    <div className="page-container">
      <h1 className="text-3xl mb-4 mt-1 jost-bold">Games</h1>
    </div>
  );
}

export default Games;

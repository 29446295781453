import React, { useState } from 'react';
import NavItem from './NavItem';
import Locales from '../locale';
import { useSpring, animated } from '@react-spring/web';
import { House, ListChecks, ShoppingCart, UsersThree, Trophy, GameController } from '@phosphor-icons/react';

function BottomNavbar({ 
  currentPage, setCurrentPage, colorKit, 
  setRefreshRequired, setGiftTargetUser, setDisguisedUser 
}) {
  const [clickCounter, setClickCounter] = useState(0);
  const { backgroundColor } = useSpring({
    backgroundColor: !["home"].includes(currentPage)
      ? 'rgba(6, 6, 10, 100%)'
      : 'rgba(0, 0, 0, 0.5%)',
    config: { duration: 400 },
  });

  return (
    <animated.nav
      className="fixed bottom-0 left-0 right-0"
      style={{
        paddingBottom: "4vh",
        backgroundColor,
      }}
    >
      <div className="flex justify-around items-center py-2 jost-regular">
        <NavItem
          onClick={() => {
            setCurrentPage('home');
            setDisguisedUser(null);
            setRefreshRequired(true);
          }}
          icon={<House size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_home")}
          isActive={currentPage === 'home'}
        />
        <NavItem
          onClick={() => {
            setDisguisedUser(null);
            setRefreshRequired(true);
            setCurrentPage('leaderboard');
          }}
          icon={<Trophy size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_leaderboard")}
          isActive={currentPage === 'leaderboard'}
        />
        <NavItem
            onClick={() => {
              setDisguisedUser(null);
              setRefreshRequired(true);
              setCurrentPage('tasks');
            }}
            icon={<ListChecks size={24} weight="bold" />}
            colorKit={colorKit}
            label={Locales.localize("navbar_tasks")}
            isActive={currentPage === 'tasks'}
          />
        <NavItem
          onClick={() => {
            setClickCounter(clickCounter + 1);
            if (clickCounter === 10) {
              setClickCounter(0);
              setCurrentPage('field');
            }
          }}
          icon={<GameController size={24} weight="bold" />}
          colorKit={colorKit}
          disabled={true}
          label={Locales.localize("navbar_games")}
          isActive={currentPage === 'games' || currentPage === 'field'}
        />
        <NavItem
          onClick={() => {
            setDisguisedUser(null);
            setRefreshRequired(true);
            setCurrentPage('frens');
          }}
          icon={<UsersThree size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_frens")}
          isActive={currentPage === 'frens'}
        />
      </div>
    </animated.nav>
  );
}

export default BottomNavbar;

import React from 'react';
import { 
    useTransition, 
    animated 
} from '@react-spring/web';

const AnimatedPage = ({ currentPage, renderPage }) => {
  const transitions = useTransition(currentPage, {
    key: currentPage,
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
    config: { tension: 170, friction: 26 },
  });

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden', display: 'flex', alignItems: 'flex-start', marginTop: '-18vh' }}>
      {transitions((style, item) => (
        <animated.div style={{ ...style, position: 'absolute', width: '100%' }}>
          {renderPage(item)}
        </animated.div>
      ))}
    </div>
  );
};

export default AnimatedPage;

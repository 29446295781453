import React, { useEffect, useState } from "react";
import { getTasks, claimTaskReward, createStarsInvoiceLink } from "../api/client";
import Locales from "../locale";

function getProgressColor(percent) {
  if (percent < 33) {
    return "bg-red-500";
  } else if (percent < 66) {
    return "bg-yellow-500";
  } else {
    return "bg-green-500";
  }
}

function calculateProgressPercent(progress, resources, globalVariables = {}) {
  if (!progress || !progress.percentFormula) {
    return 0;
  }

  let variables = Object.assign({}, calculateVariables(
    progress, resources), globalVariables);

  let percentFormula = progress.percentFormula;
  for (let variable of Object.keys(variables)) {
    percentFormula = percentFormula.replaceAll(variable, variables[variable]); 
  }

  return Math.min(100, eval(percentFormula));
}

function prepareProgressDescription(progress, resources, globalVariables = {}) {
  if (!progress || !progress.description) {
    return 0;
  }

  let variables = Object.assign({}, calculateVariables(
    progress, resources), globalVariables);

  let description = Locales.localizeSlice(progress.description);
  for (let variable of Object.keys(variables)) {
    description = description.replaceAll(variable, variables[variable]); 
  }

  return description;
}

function calculateVariables(progress, resources) {
  const variablesResult = {};
  if (!progress || !progress.variables) {
    return variablesResult;
  }

  const variables = progress.variables.split(',');
  for (let variable of variables) {
    let result = 0;
    switch (variable) {
      case "$TOTAL_BIRDS":
        for (let birds of resources.birds) {
          result += birds;
        }
        break;
    }

    variablesResult[variable] = result;
  }

  return variablesResult;
}

const TaskCard = ({
  colorKit,
  resources,
  handleAction,
  id,
  title,
  status,
  rewards,
  globalVariables,
  progress = null,
  paymentMode,
  actionLabel = "Open",
  actionPayload = null,
}) => {
  const [tryClaimPossible, setTryClaimPossible] = useState(false);
  const progressColor = progress ? getProgressColor(calculateProgressPercent(progress, resources, globalVariables)) : "";
  const reachedProgressEnd = progress ? calculateProgressPercent(progress, resources, globalVariables) == 100 : false;

  return (
    <div className="relative rounded-lg p-6 mb-6" style={{ boxShadow: "5px 5px 50px " + colorKit.shadow }}>
      {status === "completed" && (
        <span className="absolute top-6 right-6 px-3 py-1 text-sm bg-green-100 text-green-600 rounded-full jost-regular" style={{ opacity: 0.95 }}>
          {Locales.localize("tasks_completed")}
        </span>
      )}
      <h3 className={"text-lg w-full jost-bold" + (status === "completed" ? " max-w-[calc(100%-100px)]" : "")}>{Locales.localizeSlice(title)}</h3>
      <p className="mb-2 text-sm jost-regular" style={{ color: status == "completed" ? (progress ? "green" : "lightgreen") : "white", opacity: status == "completed" ? 1 : 0.65 }}>
        {Locales.localize("tasks_reward_label")}: {rewards}
      </p>
      {progress && (
        <>
          <div className="w-full rounded-full h-2.5 mb-2" style={{ backgroundColor: "#e5e7eb8a" }}>
            <div
              className={`h-2.5 rounded-full ${progressColor}`}
              style={{ width: `${calculateProgressPercent(progress, resources, globalVariables)}%`, opacity: 0.85 }}
            ></div>
          </div>
          <p className="text-sm text-gray-500 mb-2 jost-regular" style={{ opacity: 0.75, color: status == "completed" || status == "ready_to_claim" || reachedProgressEnd ? 
            "lightgreen" : "#FF4500" }}>{prepareProgressDescription(progress, resources, globalVariables)}</p>
        </>
      )}
      {(status === "not_completed" && actionPayload && !tryClaimPossible && !reachedProgressEnd) && (
        <button
          style={{ backgroundColor: colorKit.accent }}
          className="mt-1 w-full text-white py-2 px-4 rounded-lg focus:outline-none jost-regular flex items-center justify-center"
          onClick={() => handleAction("start", actionPayload, id, setTryClaimPossible)}
        >
          {Locales.localizeSlice(actionLabel)}
          {actionPayload.type === "sendTransaction" && " " + actionPayload.amount * (paymentMode === "stars" ? 100 : paymentMode === "ton" ? 1 : 1)}
          {actionPayload.type === "sendTransaction" && paymentMode === "ton" && (
            <img
              src="ton.svg"
              alt="TON"
              style={{ marginLeft: '6px', width: '20px', height: '20px' }}
            />
          )}
          {actionPayload.type === "sendTransaction" && paymentMode === "stars" && (
            <img
              src="stars.svg"
              alt="Telegram Stars"
              style={{ marginLeft: '6px', width: '20px', height: '20px' }}
            />
          )}
        </button>
      )}
      {((status === "ready_to_claim" && actionPayload) || ((tryClaimPossible || reachedProgressEnd) && status !== "completed")) && (
        <button
          style={{ backgroundColor: colorKit.accent }}
          className="mt-1 w-full text-white py-2 px-4 rounded-lg focus:outline-none jost-regular"
          onClick={() => handleAction("claim", actionPayload, id, setTryClaimPossible)}
        >
          {Locales.localize("tasks_claim")}
        </button>
      )}
    </div>
  );
};

function Tasks({ 
  colorKit, telegramInitData, setCurrentPage, 
  resources, telegramUserId, paymentMode, 
  performTransaction, setRefreshRequired, tasksRefreshRequired,
  setTasksRefreshRequired, refreshRequired
}) {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getTasks(telegramInitData);
      setTasks(response && response.tasks || []);
      setVariables(response && response.variables || {});
      setTasksRefreshRequired(false);
      setLoading(false); 
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (tasksRefreshRequired) {
        setLoading(true);
        const response = await getTasks(telegramInitData);
        setTasks(response && response.tasks || []);
        setVariables(response && response.variables || {});
        setTasksRefreshRequired(false);
        setLoading(false); 
      }
    })();
  }, [tasksRefreshRequired]);

  const createRewardLabel = (rewards) => {
    return rewards
      .map((subreward) => {
        let output = subreward.type != "booster"
          ? `${subreward.amount} ` : "";

        if (subreward.type === "bird") {
          if (subreward.subtype === "blue") {
            output += Locales.localize("tasks_reward_blue_ru_fem", subreward.amount);
          } else if (subreward.subtype === "green") {
            output += Locales.localize("tasks_reward_green_ru_fem", subreward.amount);
          } else if (subreward.subtype === "yellow") {
            output += Locales.localize("tasks_reward_yellow_ru_fem", subreward.amount);
          } else if (subreward.subtype === "orange") {            
            output += Locales.localize("tasks_reward_orange_ru_fem", subreward.amount);
          } else if (subreward.subtype === "red") {
            output += Locales.localize("tasks_reward_red_ru_fem", subreward.amount);
          }
          output += " " + Locales.localize("tasks_reward_bird", subreward.amount);
        } else if (subreward.type === "silver") {
          output += Locales.localize("tasks_reward_silver", subreward.amount);
        } else if (subreward.type === "gold") {
          output += Locales.localize("tasks_reward_gold", subreward.amount);
        } else if (subreward.type === "booster") {
          if (subreward.subtype === "x2_1h") {
            output += Locales.localize("tasks_reward_booster_x2_1h");
          } else if (subreward.subtype === "x2_24h") {
            output += Locales.localize("tasks_reward_booster_x2_24h");
          } else if (subreward.subtype === "x10_1h") {
            output += Locales.localize("tasks_reward_booster_x10_1h");
          }
        } else if (subreward.type === "color") {
          if (subreward.subtype === "blue") {
            output += Locales.localize("tasks_reward_blue_ru_male");
          } else if (subreward.subtype === "green") {
            output += Locales.localize("tasks_reward_green_ru_male");
          } else if (subreward.subtype === "yellow") {
            output += Locales.localize("tasks_reward_yellow_ru_male");
          } else if (subreward.subtype === "orange") {            
            output += Locales.localize("tasks_reward_orange_ru_male");
          } else if (subreward.subtype === "red") {
            output += Locales.localize("tasks_reward_red_ru_male");
          }
          output += " " + Locales.localize("tasks_reward_color");
        }
        return output;
      })
      .join(", ");
  };

  const sortedTasks = tasks.sort((a, b) => {
    if (a.status === "completed" && b.status !== "completed") {
      return 1;
    } else if (a.status !== "completed" && b.status === "completed") {
      return -1;
    } else {
      return 0;
    }
  });

  const availableTasks = sortedTasks.filter((task) => {
    if (!task.dependsOn) {
      return true;
    }

    for (let _task of sortedTasks) {
      if (_task.id == task.dependsOn) {
        return _task.status == "completed";
      }
    }

    return false;
  });

  const handleShareClick = () => {
    const text = Locales.localize("share_message");
    const uri = "t.me/yourchicksbot?start=" + (Number(telegramUserId) + 26634);
    window.Telegram.WebApp.openTelegramLink("https://t.me/share/url?url=" + encodeURIComponent(uri) + "&text=" + encodeURIComponent(text));
  };

  function handleAction(type = "start", actionPayload = {}, taskId = -1, setTryClaimPossible = () => {}) {
    if (type == "start") {
      if (actionPayload.type == "openTelegramLink") {
        window.Telegram.WebApp.openTelegramLink(actionPayload.link);
        setTimeout(() => {
          setTryClaimPossible(true);
        }, 1250);
      } else if (actionPayload.type == "openLink") {
        window.Telegram.WebApp.openLink(actionPayload.link);
        setTimeout(() => {
          setTryClaimPossible(true);
        }, 1250);
      } else if (actionPayload.type == "showPage") {
        setCurrentPage(actionPayload.page);
      } else if (actionPayload.type == "shareApp") {
        handleShareClick();
      } else if (actionPayload.type == "sendTransaction") {
        if (paymentMode == "ton") {
          performTransaction(
            actionPayload.amount,
            btoa([Number(telegramUserId) - 26634, -1, actionPayload.payload].join(':'))
          );
        } else if (paymentMode == "stars") {
          (async () => {
            try {
              const { link } = await createStarsInvoiceLink(
                telegramInitData, "-1:" + actionPayload.payload, telegramUserId
              );

              if (link) {
                let executionCount = 0;
                window.Telegram.WebApp.openTelegramLink(link);
                const intervalId = setInterval(() => {
                  setTasksRefreshRequired(true);
                  setRefreshRequired(true);
                  executionCount += 1;
                  if (executionCount >= 60) {
                    clearInterval(intervalId);
                  }
                }, 1000);
              }
            } catch (e) {
              // ...
            }
          })();
        }
      }
    } else if (type == "claim") {
      (async () => {
        const { 
          success
        } = await claimTaskReward(telegramInitData, taskId);
        
        if (!success) {
          handleAction("start", actionPayload, taskId, setTryClaimPossible);
        } else {
          const response = await getTasks(telegramInitData);
          setTasks(response && response.tasks || []);
        }
      })();
    }
  }

  return (
    <div className="page-container">
      <h1 className="text-3xl jost-bold mb-8 text-center mt-1" style={{ color: colorKit.secondary }}>{Locales.localize("tasks_title")}</h1>
      {loading && availableTasks.length == 0 ? (
        <div className="loading-section text-center mt-8">
          <p className="text-lg mb-4 jost-regular">{Locales.localize("loading")}</p>
        </div>
      ) : (
        <div className="tasks-container overflow-auto h-[calc(100vh-6rem)] mt-4 p-4 rounded-md" style={{ paddingBottom: "20rem" }}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {availableTasks.map((task, index) => (
              <TaskCard
                key={index}
                paymentMode={paymentMode}
                colorKit={colorKit}
                resources={resources}
                handleAction={handleAction}
                globalVariables={variables}
                {...task}
                rewards={createRewardLabel(task.rewards)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );  
}

export default Tasks;

import React, { useEffect, useState } from 'react';
import { useSpring, animated } from "@react-spring/web";

function Vignette({ isVignetteShimming, colorKit }) {
  const [breathing, setBreathing] = useState(false);

  const animationProps = useSpring({
    opacity: isVignetteShimming ? (breathing ? 1 : 0.55) : 0.75,
    config: { tension: 50, friction: 20 },
  });

  useEffect(() => {
    if (isVignetteShimming) {
      const intervalTime = 1500 / Number(isVignetteShimming);
      const interval = setInterval(() => {
        setBreathing(prev => !prev);
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [isVignetteShimming]);

  return (
    <animated.div
      className="vignette"
      style={{
        opacity: animationProps.opacity,
        background: `radial-gradient(circle, transparent 60%, ${colorKit.gradient} 100%)`,
      }}
    />
  );
}

export default Vignette;
